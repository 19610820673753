<template>
  <v-container>
    <BaseTitlePage>
      <template v-slot:button>
        <BaseButton
          id="btn-create"
          name="btn-create"
          color="secondary"
          title="Novo cadastro"
          icon="fa-solid fa-plus"
          @click="create(null, false)"
          v-permission="{
            roles: ['C1', 'C2', 'C3', 'F1', 'F2', 'F3', 'CC1', 'CC2'],
            operator: '!=='
          }"
        />
      </template>
    </BaseTitlePage>

    <BaseSearchPage title="Pesquisar estipulante">
      <template v-slot:form>
        <v-form @submit.prevent="search" ref="form" lazy-validation>
          <v-row dense>
            <v-col cols="12" xl="3" lg="3" md="3" sm="4">
              <v-text-field
                id="document"
                name="document"
                label="CNPJ"
                hide-details
                clearable
                dense
                outlined
                v-mask="'##.###.###/####-##'"
                v-model="filter.document"
              />
            </v-col>
            <v-col cols="12" xl="5" lg="5" md="5" sm="4">
              <v-text-field
                id="name"
                name="name"
                label="Razão social"
                dense
                outlined
                hide-details
                clearable
                v-model="filter.name"
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="4">
              <v-autocomplete
                id="situation"
                name="situation"
                label="Status"
                clearable
                dense
                outlined
                hide-details
                :items="situation"
                v-model="filter.situation"
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="4">
              <BaseButton
                id="btn-search"
                name="btn-search"
                type="submit"
                height="40"
                color="primary"
                title="Filtrar"
                icon="fa-solid fa-magnifying-glass"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </BaseSearchPage>

    <BaseTableList title="Listagem de estipulantes">
      <template v-slot:table>
        <v-data-table
          dense
          item-key="id"
          disable-sort
          show-expand
          single-expand
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalRecords"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right'
          }"
        >
          <template
            v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }"
          >
            <v-icon
              small
              v-if="!isExpanded && item.children.length"
              @click="expand(!isExpanded)"
            >
              fa-solid fa-chevron-down
            </v-icon>
            <v-icon
              small
              v-if="isExpanded && item.children.length"
              @click="expand(!isExpanded)"
            >
              fa-solid fa-chevron-up
            </v-icon>
          </template>

          <template v-slot:[`item.parentId`]="{ item }">
            {{ !item.parentId ? 'Estipulante' : 'Subestipulante' }}
          </template>

          <template v-slot:[`item.socialName`]="{ item }">
            <span :title="item.socialName">
              {{ doTruncateText(item.socialName, 25) }}
            </span>
          </template>

          <template v-slot:[`item.document`]="{ item }">
            {{ formatCnpj(item.document) }}
          </template>

          <template v-slot:[`item.situation`]="{ item }">
            <span :class="getColor(item.situation)">{{ item.situation }}</span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="success"
                  class="mr-2"
                  small
                  @click="edit(null, item, false)"
                  v-permission="{
                    roles: ['C1', 'C2'],
                    operator: '!=='
                  }"
                >
                  fa-solid fa-pencil
                </v-icon>
              </template>
              <span>Visualizar</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="primary"
                  small
                  @click="create(item, true)"
                  v-permission="{
                    roles: ['C1', 'C2', 'C3', 'F1', 'F2', 'F3', 'CC1', 'CC2'],
                    operator: '!=='
                  }"
                >
                  fa-regular fa-building
                </v-icon>
              </template>
              <span>Cadastrar Subestipulante</span>
            </v-tooltip>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" style="padding: 0">
              <v-simple-table style="border: none !important" dense>
                <template v-slot:default>
                  <tbody>
                    <tr
                      v-for="(child, index) in item.children"
                      :key="index"
                      class="v-data-table__expanded__row"
                    >
                      <td class="text-start" width="7%"></td>
                      <td class="text-start" width="15%">
                        {{ !child.parentId ? 'Estipulante' : 'Subestipulante' }}
                      </td>
                      <td
                        class="text-start"
                        width="30%"
                        :title="child.socialName"
                      >
                        {{ doTruncateText(child.socialName, 25) }}
                      </td>
                      <td class="text-start" width="20%">
                        {{ formatCnpj(child.document) }}
                      </td>
                      <td
                        class="text-start"
                        width="15%"
                        :class="getColor(child.situation)"
                      >
                        {{ child.situation }}
                      </td>
                      <td class="text-start" width="15%">
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                              color="success"
                              small
                              @click="edit(child, item, true)"
                              v-permission="{
                                roles: ['C1', 'C2'],
                                operator: '!=='
                              }"
                            >
                              fa-solid fa-pencil
                            </v-icon>
                          </template>
                          <span>Visualizar subestipulante</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </template>
        </v-data-table>
      </template>
    </BaseTableList>
  </v-container>
</template>

<script>
import { getColor, doTruncateText, unmask } from '@/helpers/utils';
import { formatCnpj } from '@/helpers/formatting';
import { CustomerService } from '@/services/api/customers';

export default {
  data: () => ({
    items: [],
    totalRecords: 0,
    filter: {},
    options: {},
    headers: [
      { text: '', value: 'data-table-expand', width: '7%' },
      { text: 'Tipo', value: 'parentId', width: '15%' },
      { text: 'Razão social', value: 'socialName', width: '30%' },
      { text: 'CNPJ', value: 'document', width: '20%' },
      { text: 'Status', value: 'situation', width: '15%' },
      { text: '', value: 'action', width: '15%' }
    ],
    situation: ['Ativo', 'Cancelado']
  }),

  methods: {
    formatCnpj,
    getColor,
    doTruncateText,

    async search() {
      try {
        const { page, itemsPerPage } = this.options;
        const { name, document, product, situation } = this.filter;
        const customerService = new CustomerService();
        const { status, data } = await customerService.getCustomers({
          page,
          take: itemsPerPage,
          name,
          document: unmask(document),
          product,
          situation
        });

        if (status === 200) {
          this.items = data.results;
          this.totalRecords = data.totalRecords;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    create(item, sub) {
      if (sub) {
        this.$router.push({
          name: 'estipulantes-edit',
          params: {
            sub,
            cnpj: item.document,
            socialName: item.socialName,
            parentId: item.id
          }
        });
      } else {
        this.$router.push({ name: 'estipulantes-edit' });
      }
    },

    edit(child, item, sub) {
      if (sub) {
        this.$router.push({
          name: 'estipulantes-edit',
          params: {
            id: child.id,
            sub,
            cnpj: item.document,
            socialName: item.socialName,
            parentId: item.id
          }
        });
      } else {
        this.$router.push({
          name: 'estipulantes-edit',
          params: { id: item.id }
        });
      }
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
